<template>
  <div>
    <div class="page-heading">
      <h1 class="h3">{{ video.title }}</h1>
    </div>
    <div class="row" v-if="is_loaded">
      <div class="col-md-9">
        <vimeo-player
          v-if="!video.mega"
          class="vimeo-player"
          ref="player"
          :video-url="video.video_url"
          player-width="auto"
          player-height="auto"
          :options="{ responsive: true }"
        />

        <mega-player :src="video.video_url" v-if="video.mega" />

        <div class="card mt-2">
          <div class="px-3 py-0">
            <video-comments
              :about="video.title"
              type="elite-video"
              :id="$route.params.id"
              role="admin"
              v-if="is_loaded"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="px-2 py-2">
            <table
              class="table table-responsive table-borderless table-condensed"
            >
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>{{ video.published ? "Published" : "Not Published" }}</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>{{ video.date }}</td>
                </tr>
                <tr>
                  <td>Views</td>
                  <td>{{ video.views }}</td>
                </tr>
                <tr>
                  <td>Comments</td>
                  <td>{{ video.comments }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <a
          href="#"
          class="btn btn-primary btn-sm btn-block"
          @click.prevent="edit_video = true"
          >Edit</a
        >
        <a
          href="#"
          :class="`btn btn-${
            video.published ? 'danger' : 'success'
          } btn-sm btn-block`"
          @click.prevent="toggleStatus"
          >{{ video.published ? "Unpublish" : "Publish" }}</a
        >
        <a
          href="#"
          class="btn btn-secondary btn-sm btn-block"
          @click.prevent="deleteVideo"
          >Delete Video</a
        >
      </div>
    </div>

    <modal :show="edit_video" @close="edit_video = false">
      <template slot="header">Edit Video</template>
      <form @submit.prevent="submit">
        <div class="form-group">
          <label>Title</label>
          <input
            type="text"
            required
            placeholder="Title"
            class="form-control"
            v-model="video.title"
          />
        </div>
        <div class="form-group">
          <label>Vimeo Video URL</label>
          <input
            type="text"
            required
            placeholder="Vimeo Video URL"
            class="form-control"
            v-model="video.video_url"
          />
        </div>
        <div class="form-group">
          <label>Video Thumbnail</label>
          <input
            type="file"
            placeholder="Video Thumbnail"
            class="form-control"
            ref="thumbnail"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-secondary btn-sm">
            <i class="lni lni-save mr-2"></i>Save
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      new_comment: {},
      new_reply: {},
      video: {},
      is_loaded: false,
      edit_video: false,
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.is_loaded = false;

      this.$axios
        .get(`/api/v1/admin/elite-videos/${this.$route.params.id}`)
        .then((response) => {
          this.video = response.data.video;
          this.$loader.stop();
          this.is_loaded = true;
        });
    },

    submit() {
      let data = new FormData
      data.append('_method', 'PUT')
      data.append('title', this.video.title)
      data.append('video_url', this.video.video_url)
      data.append('thumbnail', this.$refs.thumbnail.files ? this.$refs.thumbnail.files[0] : null)

      this.$axios
        .post(`/api/v1/admin/elite-videos/${this.$route.params.id}`, data)
        .then(() => {
          this.edit_video = false;
          this.fetch();
        });
    },

    toggleStatus() {
      let data = { action: "status" };

      this.$axios
        .put(`/api/v1/admin/elite-videos/${this.$route.params.id}`, data)
        .then(() => {
          this.fetch();
        });
    },

    deleteVideo() {
      this.$loader.start();

      this.$axios
        .delete(`/api/v1/admin/elite-videos/${this.$route.params.id}`)
        .then(() => {
          this.$loader.stop();
          this.$router.push({ name: "admin.elite-videos.index" });
        });
    },
  },
};
</script>
